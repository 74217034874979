<template>
  <div>
    <v-btn
      v-if="!toggle_form"
      @click="toggle_btn_form()"
      class="mt-10"
      fab
      small
    >
      <v-icon>add</v-icon>
    </v-btn>
    <v-form ref="form" class="mb-10">

      <v-card class="mt-10" v-if="toggle_form">
        <v-card-title>
          <span> {{ title }}</span>
          <!--/col (employee status)-->
        </v-card-title>
        <v-divider></v-divider>
        <!--/.card title -->

        <v-card-text>
          <v-row class="mb-3">
            <v-col cols="6" sm="3">
              <v-text-field
                :rules="[(v) => !!v || 'حقل مطلوب']"
                v-model="form_obj.occasion_name"
              >
                <template slot="label">
                  <strong class="red--text">*</strong>
                  اسم المناسبة
                </template>
              </v-text-field>
            </v-col>
            <!--/ occasion name  -->

            <v-col cols="12" sm="3">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="form_obj.date"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="form_obj.date"
                    label="التاريخ"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="form_obj.date" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false">
                    الغاء
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menu.save(form_obj.date)"
                  >
                    تم
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="6" sm="3">
              <v-select
                :items="clients"
                v-model="form_obj.client_id"
                item-text="client_name"
                item-value="id"
                :rules="[(v) => !!v || 'حقل مطلوب']"
                :loading="clients.length == 0"
              >
                <template slot="label">
                  <strong class="red--text">*</strong>
                  العميل
                </template>
              </v-select>
            </v-col>
            <!--/ clients -->

            <v-col cols="6" sm="3">
              <v-select
                :items="sections"
                v-model="form_obj.section_id"
                item-text="section_name"
                item-value="id"
                :rules="[(v) => !!v || 'حقل مطلوب']"
                :loading="sections.length == 0"
              >
                <template slot="label">
                  <strong class="red--text">*</strong>
                  قسم المناسبة
                </template>
              </v-select>
            </v-col>
            <!--/ section -->

            <v-col cols="6" sm="3">
              <v-select
                :items="conditions"
                v-model="form_obj.condition_ids"
                item-text="condition_name"
                item-value="id"
                multiple
                :loading="conditions.length == 0"
              >
                <template slot="label"> الشروط </template>
              </v-select>
            </v-col>
            <!--/ clients -->

            <v-col cols="6" sm="3">
              <v-select
                :items="forms"
                v-model="form_obj.form_id"
                item-text="form_name"
                item-value="id"
                :rules="[(v) => !!v || 'حقل مطلوب']"
                :loading="forms.length == 0"
              >
                <template slot="label">
                  <strong class="red--text">*</strong>
                  النموذج
                </template>
              </v-select>
            </v-col>
            <!--/ clients -->
          </v-row>
          <!--/ row -->

          <div class="text-end">
            <v-btn
              @click="
                $refs.form.reset(),
                  $refs.form.resetValidation(),
                  $emit('reset_id')
              "
              class="me-2"
              color="lightGray"
              outlined
            >
              <v-icon class="me-1">delete</v-icon>
              مسح
            </v-btn>
            <v-btn
              :disabled="save_loading"
              :loading="save_loading"
              @click="validate()"
              class="me-2"
              color="lightGray"
              outlined
            >
              <v-icon class="me-1" color="primary">save</v-icon>
              حفظ
            </v-btn>

            <v-btn
              @click="toggle_btn_form()"
              class="me-2"
              color="lightGray"
              outlined
            >
              رجوع
              <v-icon small class="me-1">arrow_back</v-icon>
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
      <!--/ form -->
    </v-form>

    <v-snackbar
      @input="
        ($event) => $emit('close_snack', { props: $event, type: 'success' })
      "
      type="success"
      left
      :value="form_success"
    >
      <p>
        {{ success_text }}
      </p>
      <template v-slot:action>
        <v-icon color="success" class="ms-3">check_circle</v-icon>
      </template>
    </v-snackbar>

    <!--/ alert success -->

    <v-snackbar
      @input="
        ($event) => $emit('close_snack', { props: $event, type: 'error' })
      "
      color="error"
      left
      :value="form_error"
    >
      {{ error_text }}
      <template v-slot:action>
        <v-icon color="white" class="ms-3">error</v-icon>
      </template>
    </v-snackbar>

    <!--/ alert errors -->
  </div>
</template>

<script>
export default {
  name: "Form",
  props: [
    "title",
    "form_error",
    "form_success",
    "error_text",
    "success_text",
    "save_loading",
    "form_obj",
  ],
  data() {
    return {
      menu: false,
      clients: [],
      conditions: [],
      sections: [],
      forms: [],
      toggle_form: false,
    };
  },
  computed: {
    type() {
      return [
        {
          value: "employee",
          text: "موظف",
        },
        {
          value: "company",
          text: "شركة",
        },
      ];
    },
    payment_methods() {
      return [
        {
          value: "cash",
          text: "كاش",
        },
        {
          value: "bank",
          text: "بنك",
        },
      ];
    },
  },
  methods: {
    get_sections() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: "sections",
            method: "get",
          },
        })
        .then((res) => {
          this.sections = Object.assign([], res.data.data);
          this.data_loaded = true;
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    get_clients() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: "clients",
            method: "get",
          },
        })
        .then((res) => {
          this.clients = Object.assign([], res.data.data);
          this.data_loaded = true;
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    get_forms() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: "forms",
            method: "get",
          },
        })
        .then((res) => {
          this.forms = Object.assign([], res.data.data);
          this.data_loaded = true;
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    get_conditions() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: "conditions",
            method: "get",
          },
        })
        .then((res) => {
          this.conditions = Object.assign([], res.data.data);
          this.data_loaded = true;
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    toggle_btn_form() {
      this.toggle_form = !this.toggle_form; // toggel form propery
      if (!this.toggle_form) {
        // if form is close back the employee index to default
        this.$emit("reset_id");
        this.$refs.form.reset();
        this.$refs.form.resetValidation();
        this.form_obj.type = "employee";
        this.form_obj.payment_method = "cash";
        this.form_obj.date = new Date(
          Date.now() - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 10);
      }
    },
    fade_form() {
      this.toggle_form = true;
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.$emit("save_form");
      }
    },
  },
  created() {
    this.get_sections();
    this.get_clients();
    this.get_forms();
    this.get_conditions();
  },
};
</script>
