<template>
  <v-container>
    <v-skeleton-loader type="table" v-if="!data_loaded"></v-skeleton-loader>
    <Table
      ref="table"
      :headers="headers"
      :items="occasions"
      :status="false"
      :delete_meg="'حذف المناسبة ؟ '"
      :delete_loader="delete_loader"
      :delete_dialog="delete_dialog"
      @delete_row="delete_occasion($event)"
      @edit_row="edit_row($event)"
      @close_delete="delete_dialog = false"
      @show_delete="delete_dialog = true"
      v-if="data_loaded"
      :id_dialog="id_dialog"
      @show_photo_id="id_dialog = true"
      @close_photo_id="id_dialog = false"
    ></Table>
    <!--table -->
    <Form
      v-if="data_loaded"
      :title="title"
      :form_obj="form_obj"
      ref="emForm"
      :form_error="alerts.error"
      :form_success="alerts.success"
      :success_text="alerts.success_text"
      :error_text="alerts.error_text"
      @save_form="save_form()"
      :save_loading="save_loading"
      @reset_id="(occasion_id = null), (title = 'اضافة موظف')"
      @reset_rules="reset_rules()"
      @close_snack="close_snack($event)"
    ></Form>
  </v-container>
  <!--/ container -->
</template>

<script>
import Table from "@/components/occasions/table";
import Form from "@/components/occasions/form";

export default {
  name: "expenses",

  data() {
    return {
      id_dialog: false,
      delete_dialog: false,
      occasion_id: null,
      delete_loader: false,
      occasions: [],
      title: "مناسبة جديد",
      data_loaded: false,
      save_loading: false,
      alerts: {
        success: false,
        error: false,
        success_text: "",
        error_text: "",
      },
      form_obj: {
        occasion_name: "",
        date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        section_id: null,
        client_id: null,
        form_id: null,
        condition_ids: [],
      },

      headers: [
        {
          value: "date",
          text: "التاريخ",
          align: "center",
        },
        {
          value: "client",
          text: "اسم العميل ",
          align: "center",
          sec_val: "client_name",
        },
        {
          value: "section",
          text: " قسم المناسبة ",
          align: "center",
          sec_val: "section_name",
        },
        {
          value: "form",
          text: "اسم النموذج",
          align: "center",
          sec_val: "form_name",
        },
        {
          value: "total",
          text: "اجمالي النموذج",
          align: "center",
        },
        {
          value: "password",
          text: "كلمة المرور",
          align: "center",
        },
        {
          value: "update_status",
          text: "حالة التعديل",
          align: "center",
        },
        {
          value: "form_done",
          text: "النموذج",
          align: "center",
        },
        {
          value: "actions",
          text: "اجراءات",
          align: "center",
        },
      ],
    };
  },

  methods: {
    get_occasion() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: "occasions",
            method: "get",
          },
        })
        .then((res) => {
          this.occasions = Object.assign([], res.data.data);
          this.data_loaded = true;
          console.log(res);
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    edit_row(ev) {

      Object.keys(ev.item).forEach((e) => {
        if (e != "occasion_name" && e != "date" && e != "condition_ids" ) {
          this.form_obj[`${e}_id`] = ev.item[e].id;
        } else {
          this.form_obj[e] = ev.item[e];
        }
      });
      this.occasion_id = ev.item.id;
      this.$refs.emForm.fade_form();
    },
    reset_rules() {
      Object.keys(this.rules_props).forEach((e) => {
        this.rules_props[e] = false;
      });
    },
    close_snack(ev) {
      if (!ev.prop) {
        this.alerts[ev.type] = false;
      }
    },
    save_form() {
      this.save_loading = true;
      this.$store
        .dispatch("public__request", {
          config: {
            url: this.occasion_id
              ? `occasions/${this.occasion_id}`
              : `occasions`,
            method: this.occasion_id ? "put" : "post",
          },
          data: this.form_obj,
        })
        .then((res) => {
          console.log(res);
          this.alerts.error = false;
          this.save_loading = false;
          this.alerts.success_text = "تم اضافة المناسبة بنجاح";
          this.alerts.success = true;
          this.$refs.emForm.toggle_btn_form();
          this.get_occasion();
        })
        .catch((err) => {
          console.log(err.response);
          this.save_loading = false;
          if (err.response.data.data.phone_number) {
            this.rules_props.phone_exist_prop = true;
          }
          if (err.response.data.data.id_number) {
            this.rules_props.id_number_exist = true;
          }
          this.alerts.error_text = "برجاء مراجعة البيانات";
          this.alerts.error = true;
          this.$refs.emForm.$refs.form.validate();
        });
    },
    delete_occasion(occasion_id) {
      if (occasion_id) {
        this.delete_loader = true;
        this.$store
          .dispatch("public__request", {
            config: {
              url: `occasions/${occasion_id}`,
              method: "delete",
            },
          })
          .then((res) => {
            this.alerts.success = true;
            this.alerts.success_text = "تم حذف المناسبة";
            this.delete_loader = false;
            this.get_occasion();
            this.delete_dialog = false;
            if (this.$refs.emForm.toggle_form == true) {
              this.$refs.emForm.toggle_btn_form();
            }
          })
          .catch((err) => {
            console.log(err.response);
          });
      }
    },
  },
  created() {
    this.get_occasion();
  },
  components: {
    Table,
    Form,
  },
};
</script>
